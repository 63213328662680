@import url( 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap' );

.bkp-form-renderer-col-7 {
  font-family: Open Sans;

  ul, p {
    font-size: 16px;
  }

  ul {
    margin-bottom: 0px;
  }
}

.bkp-form-renderer-col {
  margin-bottom: 20px;
  @media ( min-width: 992px ) {
    margin-bottom: 0px;
  }
}

.bkp-anchor {
  font-size: 16px;
}

.bkp-info-subcont {
  >img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  display: flex;
  align-items: center !important;
  font-size: 18px;
  margin-left: -5px !important;
  margin-bottom: 12px !important;
}

.bkp-form-renderer-form-hover, 
.bkp-form-renderer-title-hover, 
.bkp-form-renderer-card-hover {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    transform: scale( 1.02 );
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  }
}
.bkp-form-tab-selection-grid {

  display: grid;
  gap: 1rem;
  place-content: center;
  grid-template-columns: repeat( 3, minmax(0, 1fr) );
  
  @media screen and ( max-width: 760px ) and ( min-width: 500px ) {
    grid-template-columns: repeat( 5, minmax(0, 1fr) );
  }

  >.bkp-form-tab-selection-btn {
    background: white;
    border: none;
    aspect-ratio: 1/1;
    border-radius: 0.3rem;
    transition: 400ms;
    box-shadow: 0 0 0 1px #6c757d;

    &:hover {
      filter: brightness( 0.9 );
    };
    >:is( svg, p ) {
      color: #6c757d;
    };
    >svg {
      width: 1.8rem;
      height: 1.8rem;
    }
    >p {
      margin-top: 8px;
      margin-bottom: 0px;
      font-size: 0.6rem;
    }
  }

  >.bkp-form-tab-selection-btn-selected {
    background: #6c757d;
    box-shadow: 0 0 0 1px white;

    >:is( svg, p ) {
      color: white
    };
  }
}
